import { graphql, useStaticQuery } from "gatsby"
import { useState } from "react"
import { Search } from "react-feather"
import { Button, Form, FormGroup, Input, InputGroup } from "reactstrap"

const JournalCard = () => {
  const journals = useStaticQuery(graphql`
    {
      allContentfulJournals {
        nodes {
          id
          title
          summary {
            summary
          }
          publishedDate(formatString: "DD MMMM YYYY")
          link
        }
      }
    }
  `)

  const [query, setQuery] = useState("")
  // const [filterParam, setFilterParam] = useState(["All"])
  const [sortOrder, setSortOrder] = useState("Newest")
  const [sortedArray] = useState([...journals.allContentfulJournals.nodes])

  if (sortOrder === "Newest") {
    sortedArray.sort(function (a, b) {
      return new Date(b.publishedDate) - new Date(a.publishedDate)
    })
  } else if (sortOrder === "Oldest") {
    sortedArray.sort(function (a, b) {
      return new Date(a.publishedDate) - new Date(b.publishedDate)
    })
  }

  function search(journals) {
    return journals.filter((journal) => {
      return (
        journal.title.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
      )
    })
  }

  return (
    <div className="container">
      <h1
        className="elementor-heading-title elementor-size-default title-size"
        id="padding-title-main"
      >
        Journal
      </h1>
      <Form className="mt-3">
        <div className="d-flex justify-content-between">
          <div>
            <FormGroup className="form-publication">
              <Input
                type="select"
                className="me-3"
                onChange={(e) => setSortOrder(e.target.value)}
              >
                <option value="Newest">Newest</option>
                <option value="Oldest">Oldest</option>
              </Input>
            </FormGroup>
          </div>
          <div>
            <FormGroup className="form-publication">
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Search.."
                  className="p-1"
                  onChange={(e) => setQuery(e.target.value)}
                />
                <Button
                  style={{ backgroundColor: "#00623D" }}
                  onClick={(e) => e.preventDefault()}
                >
                  <Search size={16} />
                </Button>
              </InputGroup>
            </FormGroup>
          </div>
        </div>
      </Form>

      <div className="journals-container pb-3">
        <div className="container journal-grid">
          {search(sortedArray).map((journal) => {
            const id = journal.id
            const title = journal.title
            const date = journal.publishedDate
            const summary = journal.summary.summary
            const link = journal.link

            return (
              <div className="journal-card" key={id}>
                <h2 className="journal-title">{title}</h2>
                <p className="journal-published my-2 py-2">
                  Published Date: {date}
                </p>
                <p className="journal-description my-2 py-2">{summary}</p>
                <a href={link} target="_blank">
                  {" "}
                  <button className="btn-journal">View Journal</button>
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default JournalCard
